// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliati-js": () => import("./../../../src/pages/affiliati.js" /* webpackChunkName: "component---src-pages-affiliati-js" */),
  "component---src-pages-assicurativo-js": () => import("./../../../src/pages/assicurativo.js" /* webpackChunkName: "component---src-pages-assicurativo-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-company-info-js": () => import("./../../../src/pages/company-info.js" /* webpackChunkName: "component---src-pages-company-info-js" */),
  "component---src-pages-cosa-facciamo-js": () => import("./../../../src/pages/cosa-facciamo.js" /* webpackChunkName: "component---src-pages-cosa-facciamo-js" */),
  "component---src-pages-fullstack-web-developer-js": () => import("./../../../src/pages/fullstack-web-developer.js" /* webpackChunkName: "component---src-pages-fullstack-web-developer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lavora-con-noi-js": () => import("./../../../src/pages/lavora-con-noi.js" /* webpackChunkName: "component---src-pages-lavora-con-noi-js" */),
  "component---src-pages-privacy-policy-es-js": () => import("./../../../src/pages/Privacy-Policy_es.js" /* webpackChunkName: "component---src-pages-privacy-policy-es-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/Privacy-Policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-privacy-policy-sito-web-js": () => import("./../../../src/pages/Privacy-Policy-Sito-web.js" /* webpackChunkName: "component---src-pages-privacy-policy-sito-web-js" */),
  "component---src-pages-termini-e-condizioni-js": () => import("./../../../src/pages/Termini-e-condizioni.js" /* webpackChunkName: "component---src-pages-termini-e-condizioni-js" */),
  "component---src-pages-termini-e-condizioni-sito-web-js": () => import("./../../../src/pages/Termini-e-condizioni-Sito-web.js" /* webpackChunkName: "component---src-pages-termini-e-condizioni-sito-web-js" */)
}

